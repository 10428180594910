/* @import "~antd/lib/style/default.css"; */
/* @import "~antd/lib/layout/style"; */
/* @import "~antd/lib/grid/style"; */
/* @import "~antd/lib/typography/style"; */
/* @import "~antd/lib/form/style"; */
/* @import "~antd/lib/input/style"; */
/* @import "~antd/dist/antd.css"; */
@import '~antd-mobile/es/global/global.css';

:root {
  /**
   * Antd mobile variables
   */
  --adm-font-family: -apple-system, blinkmacsystemfont, 'Helvetica Neue', helvetica, segoe ui, arial, roboto,
    'PingFang SC', 'miui', 'Hiragino Sans GB', 'Microsoft Yahei', sans-serif;
  --adm-color-primary: var(--primary-blue-8);
  --adm-color-success: var(--green-success);
  --adm-color-warning: #ff8f1f;
  --adm-color-danger: #ff3141;
  --adm-color-white: var(--white);
  --adm-color-weak: #999999;
  --adm-color-light: #cccccc;
  --adm-border-color: #eeeeee;
  --adm-color-text: var(--gray-9);
  --adm-font-size-main: 14px;

  --image-uploader-width: 84px;
  --image-uploader-height: 120px;

  /**
  * Custom variables
  */
  /* Fonts */
  --font-base: 'Inter', var(--adm-font-family);
  --font-second: 'Montserrat', var(--adm-font-family);

  /* Shadows */
  --box-shadow-1: 0 2px 4px rgba(19, 37, 48, 0.12), 0 0 8px rgba(19, 37, 48, 0.16);
  --box-shadow-2: 0 0 8px 0 #13253029, 0 2px 4px 0 #1325301f;
  --box-shadow-3: inset 0 -1px 0 var(--blue-gray-3);

  /* Layout */
  --containerMinWidth: 360px;
  --containerMaxWidth: 1024px;
  --containerPadding: 8px;
  --containerPaddingTablet: 12px;

  /* Mask */
  --mask-white: rgba(255, 255, 255, 0.95);
}
