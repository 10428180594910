.claims-tabs {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 8px 0;

  :global {
    .adm-tabs {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .adm-tabs-content {
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
    }
  }
}
