.autocomplete-list {
  position: absolute;
  width: 100%;
  top: 100%;
  margin-top: 4px;
  background-color: var(--white);
  z-index: var(--modal-z-index);
  box-shadow: var(--box-shadow-2);
  border-radius: 4px;
  max-height: 60vh;
  overflow: auto;
}
