.progress-bar {
  :global {
    .adm-progress-bar-trail {
      border-radius: 50px;
    }
  }

  &.progress-size-sm :global .adm-progress-bar-trail {
    height: 4px;
  }
  &.progress-size-md :global .adm-progress-bar-trail {
    height: 8px;
  }
  &.progress-size-lg :global .adm-progress-bar-trail {
    height: 12px;
  }

  &.progress-active {
    :global {
      .adm-progress-bar-fill {
        position: relative;
        transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
      }
    }

    :global(.adm-progress-bar-fill:before) {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      inset: 0;
      background: var(--white);
      border-radius: 10px;
      opacity: 0;
      animation: progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
    }
  }
}

@keyframes progress-active {
  0% {
    transform: translateX(-100%) scaleX(0);
    opacity: 0.1;
  }

  20% {
    transform: translateX(-100%) scaleX(0);
    opacity: 0.5;
  }

  100% {
    transform: translateX(0) scaleX(1);
    opacity: 0;
  }
}
