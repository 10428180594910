.select {
  :has([aria-invalid='true']) {
    &[class*='__control'] {
      border-color: var(--states-error);
    }
  }

  .prefix {
    margin-right: 10px;
  }

  .prefix-control {
    margin-left: 8px;
  }

  [class*='__value-container'] {
    height: auto;
  }

  [class*='__indicators'] {
    height: auto;
  }

  &.size-small {
    [class*='__control'] {
      min-height: 20px;
    }

    [class*='__indicators'] {
      min-width: 20px;
    }
  }

  &.size-middle {
    [class*='__control'] {
      min-height: 30px;
    }

    [class*='__indicators'] {
      min-width: 30px;
    }
  }

  &.size-large {
    [class*='__control'] {
      min-height: 40px;
    }

    [class*='__indicators'] {
      min-width: 38px;
    }
  }

  &-item {
    display: flex;
    align-items: center;

    span {
      margin-right: 8px;
    }
  }
}
