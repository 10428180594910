.loader {
  width: 100%;
  height: 100%;
  background-color: var(--white-transparent-7);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: var(--loader-z-index);

  :global {
    .ant-spin .ant-spin-dot-item {
      background-color: var(--scheme_color);
    }
  }

  &.absolute {
    position: absolute;
  }

  &.fixed {
    position: fixed;
  }

  &.relative {
    position: relative;
    background-color: transparent;
  }

  &.small {
    :global {
      .ant-spin .ant-spin-dot {
        font-size: 24px;

        i {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  &.default {
    :global {
      .ant-spin .ant-spin-dot {
        font-size: 32px;

        i {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  &.large {
    :global {
      .ant-spin .ant-spin-dot {
        font-size: 50px;

        i {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
}
