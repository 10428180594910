.visit-return {
  display: flex;
  height: 100%;
  background-color: var(--gray-1);
  position: relative;
  padding-top: 6px;
  width: 100%;
  flex-direction: column;

  &-details {
    margin-top: 8px;
    flex: 1;
    display: flex;
  }

  &-footer {
    padding-left: 8px;
    height: 100%;
    flex: 1;
  }
}
