.input-check-list {
  :global {
    .adm-list-item,
    .adm-list-item-content-main {
      padding: 0;
    }
  }
}

.collapse-checklist {
  background-color: var(--gray-1);

  &-checked-name {
    &:global.ant-typography {
      font-size: 14px;
      line-height: 22px;
      color: var(--gray-6);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100px;
    }
  }

  :global {
    .adm-collapse-panel-header {
      box-shadow: var(--box-shadow-3);
    }

    .adm-collapse-panel-content {
      .adm-collapse-panel-content-inner > .adm-list-item {
        > .adm-list-item-content {
          display: block;
          padding: 0;

          > .adm-list-item-content-main {
            padding: 0;

            .adm-list-body {
              .adm-list-body-inner {
                > .adm-list-item {
                  > .adm-list-item-content {
                    grid-template-columns: 1fr 32px;
                    margin-bottom: 0;
                    box-shadow: var(--box-shadow-3);
                  }
                }
              }
            }
          }
        }
      }
    }

    .adm-list-item-content {
      padding: 15px;
      align-items: center;
      display: grid;
      grid-template-columns: 4fr 1fr;
    }

    .adm-list-item-content-main {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: var(--gray-9);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 10px;
    }

    .adm-list-item-content-extra {
      justify-self: flex-end;
      padding-left: 0;
    }

    .adm-list-item-content-arrow {
      justify-self: flex-end;
      margin: 0;
    }

    .adm-collapse-arrow {
      display: flex;
      align-items: center;
      transform: none;

      svg {
        transition: all 0.3s ease;
        margin-left: 5px;
        font-size: 15px;
        transform: rotate(0);
      }

      &.adm-collapse-arrow-active {
        svg {
          transform: rotate(-180deg);
        }
      }
    }
  }
}

.checklist {
  :global {
    .adm-list-body {
      overflow: hidden;
    }
  }

  .check-list {
    :global {
      .adm-check-list-item {
        .adm-list-item-content {
          padding: 15px;
          align-items: center;
          box-shadow: var(--box-shadow-3);
          display: grid;
          grid-template-columns: 1fr 32px;
        }

        .adm-list-item-content-main {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: var(--gray-9);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .adm-list-item-content-extra {
          padding: 0;
          justify-self: flex-end;

          .adm-check-list-item-extra {
            font-size: 14px;
          }
        }
      }
    }
  }
}
