.return-approve {
  background-color: var(--gray-1);
  z-index: var(--footer-z-index);
  padding: 8px;

  &.portrait {
    box-shadow: var(--box-shadow-2);
  }

  &.landscape {
    width: 100%;
    position: relative;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 0 auto;
  }

  &-total {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    flex: none;

    :global {
      .adm-button {
        flex: 1 1 40%;
        margin-right: 8px;
        height: 40px;
        font-size: 14px;
      }
    }
  }

  :global {
    .adm-list-item {
      margin: 0;
    }
  }
}
