.input {
  &:has([aria-invalid='true']) {
    &:global {
      &.ant-picker {
        border-color: var(--states-error);
      }
    }
  }

  &:global {
    &.ant-picker {
      width: 100%;
      background-color: var(--white);
      border-color: var(--gray-4);
      border-radius: 2px;
      cursor: pointer;

      &.ant-picker-disabled {
        cursor: not-allowed;
        background-color: hsl(var(--main-gray-4));
        border-color: hsl(var(--main-gray-4));

        .ant-picker-input > input,
        .ant-picker-input > input::placeholder {
          color: var(--disabled-text);
        }

        .ant-picker-input > input:hover {
          background-color: hsl(var(--main-gray-4));
          border-color: hsl(var(--main-gray-4));
        }
      }

      &.ant-picker-large {
        padding: 8px 12px;

        .ant-picker-input > input {
          font-size: 14px;
        }
      }

      &-focused {
        border-color: var(--orange-1);
        box-shadow: var(--box-shadow-3);
      }

      &-dropdown {
        .ant-picker-panel-container {
          box-shadow: var(--box-shadow-3);
        }
      }

      .ant-picker-suffix {
        color: var(--gray-5);
      }
    }
  }
}

.item-filter-field {
  display: grid;
  background-color: var(--white);
  grid-template-columns: 4fr 2fr;
  align-items: center;
  box-shadow: var(--box-shadow-3);
  padding: 15px;

  :global {
    .ant-typography:last-child {
      justify-self: flex-end;
    }
  }
}

.item-form-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  border: 1px solid var(--gray-4);
  border-radius: 2px;

  &-has-error {
    border-color: var(--states-error);
  }
}

.input {
  :global {
    .adm-picker-view-mask-top {
      -webkit-mask-image: linear-gradient(0deg, #0009, #000c 50%, #000);
    }

    .adm-picker-view-mask-bottom {
      -webkit-mask-image: linear-gradient(180deg, #0009, #000c 50%, #000);
    }
  }
}
