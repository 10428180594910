.suggestions-list {
  position: absolute;
  width: 100%;
  left: 0;
  top: calc(100% + 3px);
  z-index: var(--suggestion-z-index);
  background-color: var(--white);
  border-radius: 2px;
  max-height: 300px;
  overflow: auto;
  box-shadow: var(--box-shadow-1);
}
