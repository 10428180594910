@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Inter', sans-serif;
  }
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --main-gold: 44 100% 49%;
    --main-red-dark: 5 75% 40%;
    --main-lite-green-opacity-1: 71 53% 60% / 10%;
    --main-yellow-green: 71 57% 43%;
    --main-states-error: 6 91% 57%;
    --main-states-info: 211 29% 35%;
    --main-states-success: 170 100% 36%;

    --main-gray-0: 0 0% 100%; /* white */
    --main-gray-1: 180 11% 98%;
    --main-gray-2: 180 4% 95%;
    --main-gray-3: 200 7% 91%;
    --main-gray-4: 210 7% 83%;
    --main-gray-5: 202 6% 74%;
    --main-gray-6: 201 7% 58%;
    --main-gray-7: 203 11% 39%;
    --main-gray-9: 203 43% 13%;
    --main-gray-10: 197 64% 2%;

    --main-gray-content: 202 8% 28%;

    --main-blue-gray-1: 210 20% 98%;
    --main-blue-gray-2: 204 22% 95%;
    --main-blue-gray-3: 203 18% 91%;
    --main-blue-gray-4: 203 20% 87%;
    --main-blue-gray-5: 204 20% 81%;
    --main-blue-gray-7: 203 20% 59%;
    --main-blue-gray-8: 203 27% 42%;
    --main-blue-gray-85: 203 60% 24%;
    --main-blue-gray-9: 203 92% 15%;

    --main-blue-2: 211 100% 95%;
    --main-blue-3: 211 100% 88%;
    --main-blue-6: 211 100% 57%;
    --main-blue-7: 211 76% 50%;
    --main-blue-8: 211 100% 42%;
    --main-blue-9: 211 100% 35%;
    --main-blue-light: 211 84% 68%;

    --main-orange-1: 23 100% 98%;
    --main-orange-5: 23 91% 75%;
    --main-orange-8: 24 91% 57%;

    --cta-normal: 6 76% 47%;
    --cta-light: 6 93% 58%;

    --warning: 45 100% 49%;

    --green-success: 126 59% 46%;
    --red-error: 6 91% 57%;
    --blue-info: 211 100% 57%;

    --status-canceled: 5 64% 56%;
    --status-done: 126 59% 46%;

    --primary-foreground: 210 40% 98%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --disabled-bg: 210 7% 83%;
    --disabled-text: 0 0% 100%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }
}

@layer components {
  .scroll-vertical {
    @apply -mx-2 flex h-full flex-col overflow-y-auto overflow-x-hidden px-2;
  }
}
