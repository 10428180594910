:root {
  /* Primary Blue */
  --primary-blue-6: #238dff;
  --primary-blue-7: #1f7de0;
  --primary-blue-8: #0068d7;
  --primary-blue-9: #0058b5;

  --primary-blue-gray-8: #4d7187;

  --blue-2: #e8f3ff;
  --blue-8: #0068d7;
  --blue-light: #6bacf2;

  --gray-1: #fafbfb;
  --gray-2: #f3f4f4;
  --gray-3: #e7e9ea;
  --gray-4: #d0d3d6;
  --gray-5: #b8bdc0;
  --gray-6: #8e979c;
  --gray-7: #59666e;
  --gray-8: #2a3a44;
  --gray-9: #132530;
  --gray-10: #020709;
  --gray-content: #42494d;

  --blue-gray-1: #f9fafb;
  --blue-gray-2: #f1f4f6;
  --blue-gray-3: #e5eaed;
  --blue-gray-4: #d8e0e5;
  --blue-gray-7: #829bab;
  --blue-gray-85: #184460;
  --blue-gray-9: #032f4a;

  --orange-1: #f85a00;
  --orange-5: #f9b083;
  --orange-6: #f89e66;
  --orange-8: #f57e2e;

  /* States */
  --states-error: #f5412e;
  --states-warning: #ed5c0e;
  --states-info: #3f5872;
  --states-success: #00ba9c;
  --states-gradient-from: #ff471a;
  --states-gradient-to: #ff6700;

  /* Custom */
  --white: #ffffff;
  --black: #000000;
  --gold: #fbba00;
  --yellow: #fbbb00;
  --background-main: var(--gray-1);
  --border-main: var(--gray-3);
  --border-list: var(--blue-gray-3);
  --border-indicator: #fffaf7;
  --orange: #f68f4a;
  --yellow-green: #94aa2f;
  --green-misc: #a2c619;
  --modal-bg: rgba(250, 251, 251, 0.9);
  --lite-green-opacity-1: rgba(188, 207, 100, 0.1);
  --white-transparent-7: rgba(255, 255, 255, 0.7);
  --red-dark: #b22719;
  --red-tomato: #f5412e;

  /* Progress bar gradient */
  --progress-gradient-from: var(--states-gradient-from);
  --progress-gradient-10: #ff5716;
  --progress-gradient-25: #ff7d0d;
  --progress-gradient-50: #ffb800;
  --progress-gradient-75: #dbd400;
  --progress-gradient-100: #9ae200;

  /* Progress bar loyalty gradient */
  --loyalty-progress-bar-silver-gradient-from: #c5c7c9;
  --loyalty-progress-bar-silver-gradient-to: var(--gray-7);
  --loyalty-progress-bar-gold-gradient-from: #ececec;
  --loyalty-progress-bar-gold-gradient-to: var(--gray-6);
  --loyalty-progress-bar-platinum-gradient-from: #ffeebe;
  --loyalty-progress-bar-platinum-gradient-to: #fbbe0d;
}
