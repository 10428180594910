.input-base {
  height: inherit;

  &[aria-invalid='true'] {
    &:global {
      &.adm-input {
        border-color: var(--states-error);
      }
    }
  }

  &:global {
    &.adm-input {
      padding: 4px 8px;
      font-size: 14px;
      line-height: 22px;
      border: 1px solid var(--gray-4);
      border-radius: 2px;
      background-color: var(--white);

      &.adm-input-disabled {
        border: 1px solid hsl(var(--main-gray-5));
        background-color: hsl(var(--main-gray-5));
        opacity: 1;

        .adm-input-element {
          cursor: not-allowed;

          &::placeholder {
            color: var(--disabled-text);
          }
        }
      }

      &:focus-within {
        border-color: var(--orange-8);
      }
    }
  }

  :global {
    .adm-input-element {
      font-size: inherit;
      line-height: inherit;
      text-overflow: ellipsis;

      &::placeholder {
        color: var(--gray-7);
      }
    }
  }
}

.input-base-size {
  &-small {
    &:global.adm-input {
      font-size: 10px;
      padding: 3px 11px;
    }
  }

  &-middle {
    &:global.adm-input {
      font-size: 12px;
      padding: 0 11px;
    }
  }

  &-large {
    &:global.adm-input {
      font-size: 14px;
      padding: 8px 12px;
    }
  }
}
