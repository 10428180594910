.swipe-action {
  :global {
    .adm-swipe-action {
      width: 100%;
    }

    .adm-button {
      padding: 0;
      height: 100%;
    }
  }
}
