.stepper {
  background-color: var(--gray-1);

  :global {
    .adm-stepper {
      --height: 48px;
      --input-width: 55px;
      --button-width: 35px;
      --input-background-color: var(--white);
      --button-background-color: var(--gray-1);
      --border: 1px solid var(--gray-3);
      --border-inner: 1px solid var(--gray-3);
      --input-font-size: 16px;
      --button-text-color: var(--primary-blue-gray-8);

      .adm-stepper-input {
        --color: var(--gray-7);
      }
    }

    .adm-stepper-minus:disabled,
    .adm-stepper-plus:disabled {
      opacity: 0.5;
      --button-text-color: #c4c4c4;
    }

    .adm-stepper-minus {
      border-radius: 2px 0 0 2px;
    }

    .adm-stepper-plus {
      border-radius: 0 2px 2px 0;
    }
  }
}
