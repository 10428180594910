.ellipsis {
  :global {
    .adm-ellipsis {
      font-size: 14px;
      line-height: 22px;

      a {
        font-size: 14px;
        line-height: 22px;
        color: var(--blue-8);
        text-decoration: underline;
        padding-left: 4px;
      }
    }
  }
}
