.white {
  --scheme_color: var(--white);
}

.blue-7 {
  --scheme_color: var(--primary-blue-7);
}

.blue-8 {
  --scheme_color: var(--primary-blue-8);
}

.blue-gray-8 {
  --scheme_color: var(--primary-blue-gray-8);
}

.blue-gray-4 {
  --scheme_color: var(--blue-gray-4);
}

.blue-gray-7 {
  --scheme_color: var(--blue-gray-7);
}

.blue-gray-85 {
  --scheme_color: var(--blue-gray-85);
}

.orange-8 {
  --scheme_color: var(--orange-8);
}

.gray-3 {
  --scheme_color: var(--gray-3);
}

.gray-5 {
  --scheme_color: var(--gray-5);
}

.gray-7 {
  --scheme_color: var(--gray-7);
}

.gray-10 {
  --scheme_color: var(--gray-10);
}

.done {
  --scheme_color: hsl(var(--status-done));
}

.error {
  --scheme_color: var(--states-error);
}
