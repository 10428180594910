.search-bar {
  position: relative;

  :global {
    .adm-search-bar {
      --height: 40px;
      --padding-left: 0;
      --background: var(--white);
      --border-radius: 2px;
      --placeholder-color: var(--gray-7);
      ---placeholder-color: var(--placeholder-color);

      &-active {
        .adm-search-bar-input-box {
          border-color: var(--orange-8);
        }
      }

      .adm-search-bar-input-box {
        .adm-search-bar-input {
          order: -1;
          font-size: 14px;
          line-height: 24px;
          padding: 8px 44px 8px 12px;
        }

        .adm-search-bar-input-box-icon {
          font-size: 18px;
          color: var(--gray-7);
          display: flex;
          position: relative;
          padding-left: 6px;

          &:after {
            content: '';
            position: absolute;
            background-color: var(--blue-gray-2);
            height: 100%;
            width: 1px;
            display: block;
            left: 0;
          }
        }
      }
    }

    .adm-input-element {
      text-overflow: ellipsis;

      &::placeholder {
        font-size: 14px;
        line-height: 24px;
        color: var(--gray-7);
      }
    }
  }

  &.disabled {
    .search-bar-button-icon:after {
      background-color: var(--gray-7);
    }

    :global {
      .adm-search-bar {
        .adm-search-bar-input-box {
          cursor: not-allowed;
          background-color: hsl(var(--main-gray-5));
          border-color: hsl(var(--main-gray-5));
          color: var(--disabled-text);

          .adm-input-element::placeholder {
            color: var(--disabled-text);
          }
        }
      }
    }
  }

  &.icon {
    :global {
      .adm-search-bar {
        .adm-search-bar-input-box {
          padding-right: 12px;

          .adm-search-bar-input {
            padding: 8px 6px 8px 12px;
          }
        }
      }
    }
  }

  &-button {
    &-icon {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      padding-left: 6px;
      display: flex;

      &:after {
        content: '';
        position: absolute;
        background-color: var(--blue-gray-2);
        height: 100%;
        width: 1px;
        display: block;
        left: 0;
        top: 0;
      }

      svg {
        font-size: 18px;
      }
    }
  }
}
