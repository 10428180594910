.checkbox {
  display: flex;
  align-items: center;

  &:global {
    &.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover {
      .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
        background-color: var(--orange-8);
        border: 1px solid var(--orange-8);
      }

      .ant-checkbox-inner,
      .ant-checkbox-checked:not(.ant-checkbox-disabled):after,
      .ant-checkbox-checked:after,
      .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
        border: 1px solid var(--orange-8);
      }
    }
  }

  :global {
    .ant-checkbox-checked:after {
      border: 1px solid var(--orange-8);
    }

    .ant-checkbox-inner {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      border: 1px solid var(--gray-5);
      background-color: var(--white);
      border-radius: 3px;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 21.5%;
        width: 6px;
        height: 9px;
        opacity: 0;
        border: 2px solid var(--white);
        transform: rotate(45deg) scale(1) translate(-50%, -50%);
      }
    }

    .ant-checkbox-input {
      position: absolute;
      z-index: -1;
      width: 0;
      height: 0;
      opacity: 0;
      visibility: hidden;
      margin: 0;
      padding: 0;
    }

    .ant-checkbox-checked {
      &.ant-checkbox-disabled .ant-checkbox-inner {
        background-color: var(--disabled-bg);
        border: 1px solid var(--gray-5);
      }

      .ant-checkbox-inner {
        background: var(--orange-8);
        border-color: var(--orange-8);

        &:after {
          content: '';
          border-top: 0;
          border-left: 0;
          opacity: 1;
        }
      }
    }

    .ant-checkbox + span {
      margin-left: 8px;
    }
  }
}
