.autocomplete {
  width: 100%;

  &[aria-invalid='true'] {
    &:global {
      &.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
        .ant-select-selector {
        border-color: var(--states-error);
      }
    }
  }

  &:global {
    &.ant-input-affix-wrapper:hover {
      border-color: var(--orange-5);
    }
  }

  &:global {
    &.ant-select-single {
      height: 42px;

      .ant-select-selector .ant-select-selection-search {
        padding: 0 30px 0 0;
      }
    }

    &.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
      .ant-select-selector {
      border-color: var(--gray-4);
      box-shadow: none;

      .ant-select-selection-placeholder {
        color: var(--gray-7);
      }
    }

    &.ant-select:not(.ant-select-customize-input):not(.ant-pagination-size-changer) {
      &.ant-select-disabled {
        .ant-select-selector {
          background-color: hsl(var(--disabled-bg));
          color: var(--disabled-text);
        }

        .ant-select-selection-placeholder {
          color: var(--disabled-text);
        }
      }

      .ant-select-selector {
        border-radius: 2px;
        border: 1px solid var(--gray-4);
        background-color: var(--white);
        box-shadow: none;
        padding: 8px 12px;
        font-size: 14px;
        line-height: 22px;
        height: 42px;
      }
    }

    &.ant-select:not(.ant-select-customize-input):not(.ant-pagination-size-changer):not(.ant-select-disabled) {
      &:hover,
      &:focus,
      &:active {
        .ant-select-selector {
          border: 1px solid var(--orange-1);
          box-shadow: var(--box-shadow);
        }
      }
    }

    &.ant-select-dropdown {
      border-radius: 4px;

      .ant-select-item {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }

      .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: var(--blue-gray-2);
      }
    }
  }

  :global {
    .ant-input-affix-wrapper {
      &:focus-within {
        border-color: var(--orange-5);
        box-shadow: var(--box-shadow);
      }

      &:hover,
      &:focus,
      &:focus-within {
        border-color: var(--orange-5);
      }

      &-lg {
        border-radius: 2px;
      }
    }
  }
}
