.marker-content {
  :global {
    .ant-typography {
      display: initial;

      &:first-child {
        position: relative;
        margin-right: 8px;

        &:after {
          content: '';
          display: block;
          width: 2px;
          height: 100%;
          background-color: var(--gray-9);
          position: absolute;
          right: -9px;
          top: 0;
        }
      }

      &:last-child {
        margin-left: 8px;
      }
    }
  }
}
