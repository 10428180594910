.rich-text {
  &-ul:has(ul) ul {
    list-style-type: disc;
    padding-left: 4px;
  }

  &-ul:not(:has(ul)) {
    list-style-type: disc;
    padding-left: 4px;
  }

  p,
  span,
  ul {
    margin: 0;
  }
}
