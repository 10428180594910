.signature {
  width: 100%;
  flex: 1;
  display: flex;

  &-canvas {
    &-container {
      width: 100%;
      display: flex;
      border: 1px solid transparent;
      min-height: 150px;

      &[aria-invalid='true'] {
        border-color: var(--states-error);
      }
    }

    &-wrap {
      background-color: var(--white);
      border-radius: 4px;
      width: 100%;
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
    }

    &.disabled {
      pointer-events: none;
    }
  }

  &-panel {
    padding: 8px;
    display: flex;
    align-items: center;
    flex: none;
    justify-content: space-between;
  }
}
