.toaster {
  z-index: 112;

  &:global {
    &.Toastify__toast {
      background-color: hsl(0, 0%, 100%);
      border-radius: 0.75em;
      transition:
        background-color 0.15s,
        color 0.15s;
      max-width: calc(100% - 16px);
      margin: 8px auto;
      padding: 0;
      align-items: inherit;

      &--success {
        background-color: #58c24a;
      }

      &--error {
        background-color: #da4444;
      }

      &--warning {
        background-color: #dda743;
      }

      &--info {
        background-color: #4085c2;
      }
    }
  }

  :global {
    .Toastify__toast-body {
      padding: 0.375em 1em;
      margin: 0;
      width: 100%;

      & > div:last-child {
        display: flex;
        align-items: center;
        gap: 16px;
        height: 100%;
      }
    }

    .Toastify__close-button {
      align-self: unset;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: -1px 0 0 #ffffff6e;
      flex-direction: column;
      flex-shrink: 0;
      min-width: 50px;
      transition: box-shadow 0.15s;
      opacity: 0.7;
      color: white;
    }
  }
}
