.radio {
  display: flex;
  align-items: center;

  &:global {
    &.ant-radio-wrapper {
      margin: 0;
      margin-inline-end: 0;

      .ant-radio-inner {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        border: 1px solid var(--gray-5);
        background-color: var(--white);
        border-radius: 50px;

        &:after {
          content: '';
          top: 50%;
          left: 50%;
          transform: scale(0);
          width: 10px;
          height: 10px;
          background-color: var(--orange-8);
          border-radius: 50px;
        }
      }

      .ant-radio-checked {
        .ant-radio-inner {
          &:after {
            content: '';
            transform: scale(1) translate(3px, 3px);
          }
        }
      }
    }
  }

  :global {
    .ant-radio-group {
      width: 100%;
    }

    .ant-radio-input {
      position: absolute;
      z-index: -1;
      width: 0;
      height: 0;
      opacity: 0;
      visibility: hidden;
      margin: 0;
      padding: 0;
    }

    .ant-radio + span {
      font-size: 14px;
      line-height: 22px;
      color: var(--gray-9);
      display: block;
      margin-left: 8px;
    }
  }
}
