.visits-action {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 12px;
  align-items: center;
  height: 100%;
  width: 100%;
  min-width: 62px;

  &-type-info {
    background-color: var(--primary-blue-8);
  }

  &-type-orange6 {
    background-color: var(--orange-6);
  }

  &-type-warning {
    background-color: var(--orange-8);
  }

  &-type-danger {
    background-color: var(--states-error);
  }

  &-type-tomato {
    background-color: var(--red-tomato);
  }

  &-type-gray4 {
    background-color: var(--gray-4);
  }

  &-type-yellow {
    background-color: var(--yellow);
  }

  &-type-green {
    background-color: hsl(var(--green-success));
  }

  &-type-blue-gray3 {
    background-color: var(--blue-gray-3);

    span {
      color: var(--gray-7);
    }

    :global {
      svg {
        fill: var(--gray-7);
      }
    }
  }
}
