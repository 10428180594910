.main {
  position: relative;
  z-index: var(--main-z-index);
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  align-items: center;
  min-height: auto;
  width: 100%;
  overflow: hidden;

  &.landscape {
    display: none;
  }

  &.portrait {
    display: flex;
  }
}
