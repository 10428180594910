.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  :global {
    .adm-form > .adm-list {
      .adm-list-body {
        overflow: hidden;
      }
    }

    .adm-form-footer {
      padding: 8px;

      .ant-col:last-of-type {
        margin-top: 8px;
      }
    }
  }
}
