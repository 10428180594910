.tabs {
  &-content {
    &-active {
      display: flex;
      flex-direction: column;
      /* scroll */
      overflow: hidden;
      margin: 0 -8px;
      padding: 0 8px;
    }
  }

  :global {
    .adm-tabs-header-mask-left,
    .adm-tabs-header-mask-right {
      visibility: hidden;
    }
    .adm-tabs-content {
      padding: 0;
      height: 0; /* add scroll */
      flex: 1 0 auto;
    }
    .adm-list-item-content-prefix {
      width: 0;
    }
    .adm-tabs-tab-wrapper {
      padding: 0;
    }
    .adm-list-body-inner {
      margin: 0;
    }
    .adm-tabs-tab {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: var(--blue-gray-9);
      padding: 16px;
    }
    .adm-tabs-tab-active {
      color: var(--orange-8);
    }
    .adm-tabs-tab-line {
      background-color: var(--orange-8);
    }
  }

  &-filter {
    padding: 8px 0;
  }
}
