.radio {
  width: 100%;

  &[aria-invalid='true'] {
    :global {
      .ant-radio-wrapper {
        border-color: var(--states-error);
      }
    }
  }

  :global {
    .ant-radio-group {
      width: 100%;
    }

    .ant-radio-inner {
      border-color: var(--gray-5);
    }

    .ant-radio-inner::after {
      background-color: var(--orange-8);
    }
  }
}
