.textarea {
  border-radius: 2px;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 22px;
  min-height: 88px;

  --font-size: 14px;
  --adm-font-family: var(--font-base);
  --disabled-color: var(--disabled-text);
  --color: var(--gray-7);
  --placeholder-color: var(--gray-7);

  &.disabled {
    --placeholder-color: var(--disabled-text);

    &:global(.adm-text-area) {
      background-color: hsl(var(--main-gray-4));
      cursor: not-allowed;
    }

    :global {
      .adm-text-area-element {
        color: var(--disabled-text);
        opacity: 1;
        cursor: not-allowed;
      }

      .adm-text-area-count {
        color: var(--disabled-text);
      }
    }
  }

  &[aria-invalid='true'] {
    &:global {
      &.adm-text-area {
        border-color: var(--states-error);
      }
    }
  }

  &:global(.adm-text-area) {
    background-color: var(--white);
    border: 1px solid var(--gray-4);

    &:focus-within {
      border-color: var(--orange-8);
    }
  }

  :global {
    .adm-text-area-element {
      line-height: 22px;
    }

    .adm-text-area-count {
      font-size: 12px;
      line-height: 20px;
      color: var(--gray-5);
    }
  }
}
