.container {
  width: 100%;
}

.tabs {
  width: 100%;
  display: flex;
  justify-items: center;
  align-items: center;
  box-shadow: var(--box-shadow-3);

  &-item {
    flex: auto;
    padding: 16px 0;
    text-align: center;
    border-bottom: 2px solid transparent;

    &-active {
      border-color: var(--orange-8);

      :global {
        .ant-typography {
          color: var(--orange-8);
        }
      }
    }
  }
}
