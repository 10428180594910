.image-uploader {
  [aria-invalid='true'] {
    .image-uploader-inner {
      border-color: var(--states-error);
    }
  }

  :global {
    .adm-image-uploader {
      display: flex;
    }

    .adm-image-uploader-cell {
      width: var(--image-uploader-width);
      height: var(--image-uploader-height);
    }

    .adm-image-uploader-cell-image {
      width: var(--image-uploader-width);
      height: var(--image-uploader-height);
    }

    .adm-image-uploader-cell-delete {
      background-color: rgba(250, 250, 251, 0.5);
      top: 4px;
      right: 4px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 21px;
      height: 21px;
    }

    .adm-image-uploader-cell-delete-icon {
      position: relative;
      top: unset;
      left: unset;
      color: var(--white);
      font-size: 7px;
    }
  }

  &-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &-inner {
    background-color: var(--gray-1);
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: var(--image-uploader-width);
    height: var(--image-uploader-height);
    border-radius: 4px;
    border: 1px dashed var(--gray-3);
  }

  &-img {
    :global {
      svg {
        font-size: 32px;
        display: flex;
        margin-bottom: 8px;
      }
    }
  }
}
