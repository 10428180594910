.input-calendar {
  width: 100%;
  border: 1px solid transparent;
  font-family: var(--font-base);
  border-radius: 4px;
  background-color: var(--white);

  &[aria-invalid='true'] {
    &.input-calendar {
      border-color: var(--states-error);
    }
  }

  :global {
    .react-calendar {
      border: none;
      border-radius: 4px;
      font-family: inherit;
      line-height: inherit;
      width: 100%;

      &__viewContainer {
        margin-top: 8px;
      }

      &__month-view__weekdays {
        abbr {
          text-decoration: none;
        }
      }

      &__navigation {
        border-bottom: 1px solid var(--blue-gray-2);
        height: 40px;
        margin-bottom: 0;

        &__prev2-button,
        &__next2-button {
          display: none;
        }

        button:enabled:hover {
          background-color: unset;
        }

        button:disabled {
          background-color: unset;

          svg {
            color: var(--gray-5);
          }
        }

        &__label__labelText {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: var(--gray-9);
        }
      }

      &__tile {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px;

        abbr {
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
          color: var(--gray-8);
          border-radius: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          height: 30px;
        }

        &--now {
          background-color: unset;
          color: unset;

          abbr {
            background-color: var(--blue-2);
            color: var(--blue-8);
          }
        }

        &:disabled {
          background-color: unset;

          abbr {
            font-weight: 300;
            color: var(--gray-5);
          }
        }

        &--active,
        &--active:enabled:focus,
        &--active:enabled:hover {
          color: unset;
          background-color: unset;

          abbr {
            color: var(--white);
            background-color: var(--blue-8);
          }
        }

        &.react-calendar__month-view__days__day--neighboringMonth {
          color: unset;

          abbr {
            color: var(--gray-5);
          }
        }
      }
    }
  }
}
