.checkbox {
  &[aria-invalid='true'] {
    :global {
      .ant-checkbox-wrapper {
        border-color: var(--states-error);
      }
    }
  }

  &:global {
    &.ant-checkbox-group {
      width: 100%;
    }
  }
}
